import {
  getDataLayer,
  handleGaTracking,
  extractRidFromBrandUrl,
} from "../../utils/js/tracking";

class TPL_Brand_Design {
  constructor(componentHost) {
    this.datalayer = getDataLayer();
    if (
      this.datalayer.pageName !==
      "mgallery::mgalleryuniverse::memorable::design"
    )
      return;
    this.componentHost = componentHost;

    this.initEvents();
  }

  initEvents() {
    this.cellB192();
    this.cellE192();
    this.cellH192();
  }

  cellB192() {
    const nodes = this.componentHost.querySelectorAll(
      ".cmp-ace-anchor-navigation nav li",
    );

    nodes.forEach((node, index) => {
      const menu_label = node
        .querySelector("a")
        .href.split("#")[1]
        ?.trim()
        .toLowerCase();

      if (!menu_label) return;

      handleGaTracking({
        node,
        eventName: "menu inpage",
        additionalData: {
          bloc_name: "design menu",
          menu_position: index + 1,
          menu_label,
        },
      });
    });
  }

  cellE192() {
    const containersPresentations = Array.from(
      this.componentHost.querySelectorAll(
        ".acc-container:has(.title + .slideshow-carousel)",
      ),
    )?.filter(
      (container) => container.querySelector(".title")?.firstElementChild?.id,
    );

    containersPresentations.forEach((containerPresentation) => {
      const titleID = containerPresentation
        .querySelector(".title")
        ?.firstElementChild.id?.toLowerCase();

      if (titleID) {
        const containerToTarget = containerPresentation.nextElementSibling;
        const nodes = containerToTarget.querySelectorAll(
          ".callout-container-api .callout-section ul li",
        );

        if (!nodes) return;

        nodes.forEach((node, index) => {
          let rid = node.querySelector(".ace-callout-component")?.dataset
            .productId;

          if (!rid) return;

          handleGaTracking({
            node,
            eventName: "bloc_interact",
            additionalData: {
              bloc_name: `hotels push - ${titleID}`,
              bloc_interaction: "see hotel page",
              bloc_type: `${rid} | ${index + 1}`,
            },
          });
        });
      }
    });
  }

  cellH192() {
    const nodes = this.componentHost.querySelectorAll(".cardeditorial a");

    nodes.forEach((node) => {
      const memorableName = node.previousElementSibling
        .querySelector(".cmp-slideshow__callout-title")
        .innerText.toLowerCase()
        .trim();

      handleGaTracking({
        node,
        eventName: "bloc_interact",
        additionalData: {
          bloc_name: "highlighted memorable",
          bloc_interaction: `see ${memorableName}`,
        },
      });
    });
  }
}

new TPL_Brand_Design(document.body);
