import { getDataLayer, handleGaTracking } from "../../utils/js/tracking";

class TPL_Brand_Mindfulness {
  constructor(componentHost) {
    this.datalayer = getDataLayer();
    if (
      this.datalayer.pageName !==
      "mgallery::mgalleryuniverse::memorable::mindfulness"
    )
      return;
    this.componentHost = componentHost;

    this.initEvents();
  }

  initEvents() {
    this.cellB226();
  }

  cellB226() {
    const nodes = this.componentHost.querySelectorAll(
      ".cardeditorial .cmp-slideshow__action",
    );
    const getCarouselIndex = (node) => {
      const isNextButton = node.classList.contains(
        "cmp-slideshow__action--next",
      );

      const currentIndex = parseInt(
        node
          .closest(".cardeditorial")
          .querySelector(
            ".cmp-slideshow__actions .cmp-slideshow__action-content",
          )
          ?.innerText.split("/")[0]
          .trim(),
      );

      return isNextButton ? currentIndex + 1 : currentIndex - 1;
    };

    nodes.forEach((node) => {
      handleGaTracking({
        node,
        eventName: "bloc_interact",
        additionalData: {
          bloc_name: "mindfulness article",
          bloc_interaction: "see details",
          bloc_type: "bloc type value base",
        },
        dynamic: {
          keyToUpdate: "bloc_type",
          functionToEval: getCarouselIndex,
          staticValue: "",
        },
      });
    });
  }
}

new TPL_Brand_Mindfulness(document.body);
