import {
  getDataLayer,
  handleGaTracking,
  extractRidFromBrandUrl,
} from "../../utils/js/tracking";

class TPL_Brand_Mixology {
  constructor(componentHost) {
    this.datalayer = getDataLayer();
    if (
      this.datalayer.pageName !==
      "mgallery::mgalleryuniverse::memorable::mixology"
    )
      return;

    this.componentHost = componentHost;
    this.initEvents();
  }

  initEvents() {
    this.cellB159();
  }

  cellB159() {
    const nodes = this.componentHost.querySelectorAll(
      ".callout-container .callout-section .ace-carousel-component-v2 ul li",
    );

    nodes.forEach((node, index) => {
      const link = node.querySelector("a")?.href;

      link &&
        handleGaTracking({
          node,
          eventName: "bloc_interact",
          additionalData: {
            bloc_name: "hotels highlighted",
            bloc_interaction: "see hotel page",
            bloc_type: `${extractRidFromBrandUrl(link)} | ${index + 1}`,
          },
        });
    });
  }
}

new TPL_Brand_Mixology(document.body);
