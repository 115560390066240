import {
  getDataLayer,
  handleGaTracking,
  extractFilterNameFromAnchor,
} from "../../utils/js/tracking.ts";

class TPL_Brand_Destinations_Hub {
  constructor(componentHost) {
    this.datalayer = getDataLayer();
    if (this.datalayer.pageName !== "mgallery::destinations::hub") return;

    this.componentHost = componentHost;
    this.initEvents();
  }

  initEvents() {
    this.cellB594();
    this.cellE594();
  }

  cellB594() {
    const nodes = this.componentHost.querySelectorAll(
      ".quick-access nav ul li a",
    );

    nodes.forEach((node, index) => {
      handleGaTracking({
        node,
        eventName: "menu_inpage",
        additionalData: {
          menu_position: index + 1,
          menu_label: extractFilterNameFromAnchor(node.href),
        },
      });
    });
  }

  cellE594() {
    const nodes = this.componentHost.querySelectorAll(
      ".callout-container .ace-button button",
    );

    nodes.forEach((node) => {
      const thematic = node
        .closest(".callout-container")
        .previousElementSibling.querySelector(".ace-shorttitle")
        .id.toLowerCase();

      handleGaTracking({
        node,
        eventName: "bloc_interact",
        additionalData: {
          bloc_name: `see more ${thematic}`,
        },
      });
    });
  }
}

new TPL_Brand_Destinations_Hub(document.body);
