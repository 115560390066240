document.addEventListener("keydown", (event) => {
  if (
    event.key === "Tab" ||
    ["ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"].includes(event.key)
  ) {
    document.body.classList.add("keyboard-navigation-is-active");
  }
});

document.addEventListener("mousedown", () => {
  if (document.body.classList.contains("keyboard-navigation-is-active")) {
    document.body.classList.remove("keyboard-navigation-is-active");
  }
});
