import {
  getDataLayer,
  handleGaTracking,
  extractRidFromAllUrl,
} from "../../utils/js/tracking.ts";

class TPL_Property_Mmoment_Detail {
  constructor(componentHost) {
    this.datalayer = getDataLayer();
    if (this.datalayer.pageName !== "mgallery::hotels::mmoment") return;

    this.componentHost = componentHost;
    this.initEvents();
  }

  initEvents() {
    this.cellB625();
    this.cellE625();
  }

  cellB625() {
    const node = this.componentHost.querySelector(
      ".welcome-message .ace-welcome-component__cta a",
    );

    if (!node) return;

    handleGaTracking({
      node,
      eventName: "bloc_interact",
      additionalData: {
        bloc_name: "book now",
        bloc_interaction: `${extractRidFromAllUrl(node.href)}`,
      },
    });
  }

  cellE625() {
    const node = this.componentHost.querySelector(".paragraph .ace-button a");

    if (!node) return;

    handleGaTracking({
      node,
      eventName: "bloc_interact",
      additionalData: {
        bloc_name: "book with a room",
        bloc_interaction: `${extractRidFromAllUrl(node.href)}`,
      },
    });
  }
}

new TPL_Property_Mmoment_Detail(document.body);
