import {
  getDataLayer,
  handleGaTracking,
  extractMeetingsOrWeddingsFromUrl,
  extractRidFromBrandUrl,
} from "../../utils/js/tracking.ts";

class TPL_Brand_Meetings_Weddings {
  constructor(componentHost) {
    this.datalayer = getDataLayer();
    if (
      this.datalayer.pageName !== "mgallery::meetingscelebrations::meetings" &&
      this.datalayer.pageName !== "mgallery::meetingscelebrations::weddings"
    )
      return;

    this.componentHost = componentHost;
    this.currentType = this.datalayer.pageName.split("::")[2];
    this.initEvents();
  }

  initEvents() {
    this.cellB710B732();
    this.cellE710E732();
  }

  cellB710B732() {
    const nodes = this.componentHost.querySelectorAll(
      ".quick-access nav ul li a",
    );

    if (!nodes.length) return;

    nodes.forEach((node, index) => {
      handleGaTracking({
        node,
        eventName: "menu_inpage",
        additionalData: {
          bloc_name: "celebration",
          menu_position: index + 1,
          menu_label: extractMeetingsOrWeddingsFromUrl(node.href),
        },
      });
    });
  }

  cellE710E732() {
    const nodes = this.componentHost.querySelectorAll(
      ".callout-container-api .ace-carousel ul li",
    );

    if (!nodes.length) return;

    nodes.forEach((node, index) => {
      const itemInfos = node.querySelector(".cmp-teaser__pretitle");
      const itemTitle = itemInfos?.innerText;
      const itemLink = itemInfos?.querySelector("a")?.href;

      if (!itemLink) return;

      handleGaTracking({
        node,
        eventName: "menu_inpage",
        additionalData: {
          bloc_name: this.currentType,
          bloc_interaction: itemTitle,
          bloc_type: `${extractRidFromBrandUrl(itemLink)} | ${index + 1}`,
        },
      });
    });
  }
}

new TPL_Brand_Meetings_Weddings(document.body);
