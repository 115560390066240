import {
  getDataLayer,
  handleGaTracking,
  extractTourGuidePageNameFromUrl,
} from "../../utils/js/tracking.ts";

class TPL_Brand_Destinations_Country_City {
  constructor(componentHost) {
    this.datalayer = getDataLayer();
    if (
      this.datalayer.pageName !== "mgallery::destinations::country" &&
      this.datalayer.pageName !== "mgallery::destinations::city"
    )
      return;

    this.componentHost = componentHost;
    this.initEvents();
  }

  initEvents() {
    this.cellE551E573();
  }

  cellE551E573() {
    const node = this.componentHost.querySelector(
      ".highlight .ace-highlight__content .call-to-action a",
    );

    const link = node?.href;

    if (!link) return;

    handleGaTracking({
      node,
      eventName: "bloc_interact",
      additionalData: {
        bloc_name: "tour guide",
        bloc_interaction: extractTourGuidePageNameFromUrl(link),
      },
    });
  }
}

new TPL_Brand_Destinations_Country_City(document.body);
