import { getDataLayer, handleGaTracking } from "../../utils/js/tracking";

class TPL_Property_Dining_Hub {
  constructor(componentHost) {
    this.datalayer = getDataLayer();
    if (this.datalayer.pageName !== "mgallery::hotels::dining::homepage")
      return;

    this.componentHost = componentHost;
    this.initEvents();
  }

  initEvents() {
    this.cellE782();
    this.cellH782();
    this.cellK782();
  }

  cellE782() {
    const nodes = this.componentHost.querySelectorAll(
      ".callout-container-api .ace-callout-container-filters__tab-nav ul li button",
    );

    if (!nodes.length) return;

    nodes.forEach((node, index) => {
      const menu_label = node.dataset.filterTabId;

      if (!menu_label) return;

      handleGaTracking({
        node,
        eventName: "menu_inpage",
        additionalData: {
          bloc_name: "menu",
          menu_position: index + 1,
          menu_label,
        },
      });
    });
  }

  cellH782() {
    const node = this.componentHost.querySelector(
      ".highlight .call-to-action .ace-button a",
    );

    if (!node) return;

    handleGaTracking({
      node,
      eventName: "bloc_interact",
      additionalData: {
        bloc_name: "iconic coktail", // @TODO : attente de réponse de la data-team (car impossible de remonter ça dynamiquement)
        bloc_interaction: "discover",
      },
    });
  }

  cellK782() {
    const nodes = this.componentHost.querySelectorAll(
      ".callout-container-api .callout-section ul li",
    );

    if (!nodes.length) return;

    nodes.forEach((node, index) => {
      const serviceName = node
        .querySelector(".cmp-teaser__content .cmp-teaser__pretitle")
        ?.innerText?.trim()
        .toLowerCase();

      if (!serviceName) return;

      handleGaTracking({
        node,
        eventName: "bloc_interact",
        additionalData: {
          bloc_name: "caroussel explore more",
          bloc_interaction: serviceName,
          bloc_type: index + 1,
        },
      });
    });
  }
}

new TPL_Property_Dining_Hub(document.body);
