import { getDataLayer, handleGaTracking } from "../utils/js/tracking";

class TPL_Transverse_navigation {
  constructor(componentHost) {
    this.datalayer = getDataLayer();

    this.componentHost = componentHost;

    this.navWrapper = this.componentHost.querySelector(
      ".ace-header-navigation__nav ul.link-navigation_nav_menu-list",
    );

    if (!this.navWrapper) return;

    this.initEvents();
  }

  initEvents() {
    this.cellB330();
    this.cellE330();
  }

  cellB330() {
    const nodes = this.navWrapper.querySelectorAll(
      "li.link-navigation__menu.link-navigation__menuitem",
    );

    if (!nodes.length) return;

    nodes.forEach((node, index) => {
      const menu_label = node.innerText?.trim().toLowerCase();

      handleGaTracking({
        node,
        eventName: "menu_interact",
        additionalData: {
          menu_position: index + 1,
          menu_label,
        },
      });
    });
  }

  cellE330() {
    const node = this.navWrapper.querySelector(".ace-navbar-toggler");

    const defineButtonState = (node) => {
      const head = node.closest("header");

      if (head.classList.contains("ace-toggleNavbar--open")) {
        return "menu_close";
      } else {
        return "menu_open";
      }
    };

    handleGaTracking({
      node,
      eventName: "menu_open", // <-- will be updated
      additionalData: {
        dynamic: {
          functionToEval: defineButtonState,
          keyToUpdate: "eventName",
        },
      },
    });
  }
}

new TPL_Transverse_navigation(document.body);
