import { getDataLayer, handleGaTracking } from "../../utils/js/tracking";

class TPL_Property_Dining_Breakfast_Bar_Details {
  constructor(componentHost) {
    this.datalayer = getDataLayer();
    if (this.datalayer.pageName !== "mgallery::hotels::dining::breakfast")
      // <- @TODO ajouter bar::details + dining::details quand on les aura dans le pagename (ACE-2468)
      return;

    this.componentHost = componentHost;
    this.initEvents();
  }

  initEvents() {
    this.cellB754();
    this.cellE754();
    this.cellH754();
  }

  cellB754() {
    const base = this.componentHost.querySelector(
      ".product-booking .product-booking-info .product-booking-actions",
    );
    const nodeBook = base?.querySelector(".cmp-button--primary");
    const nodeDiscover = base?.querySelector(".cmp-button--tertiary");

    if (nodeBook) {
      handleGaTracking({
        node: nodeBook,
        eventName: "bloc_interact",
        additionalData: {
          bloc_name: "hotel information",
          bloc_interaction: "book",
        },
      });
    }

    if (nodeDiscover) {
      handleGaTracking({
        node: nodeDiscover,
        eventName: "bloc_interact",
        additionalData: {
          bloc_name: "hotel information",
          bloc_interaction: "discover the menu",
        },
      });
    }
  }

  cellE754() {
    const base = this.componentHost.querySelector(
      ".location .ace-map__content-details",
    );

    if (!base) return;

    const nodes = base.querySelectorAll(
      ".ace-map__content-location-detail, .ace-map__content-reservation-tel, .ace-map__content-reservation-mail, .ace-map__content-parking",
    );

    if (!nodes.length) return;

    nodes.forEach((node) => {
      let bloc_name;
      if (node.classList.contains("ace-map__content-location-detail")) {
        bloc_name = "adress";
      } else if (node.classList.contains("ace-map__content-reservation-tel")) {
        bloc_name = "phone";
      } else if (node.classList.contains("ace-map__content-reservation-mail")) {
        bloc_name = "mail";
      } else if (node.classList.contains("ace-map__content-parking")) {
        bloc_name = "parking";
      }

      handleGaTracking({
        node,
        eventName: "menu_inpage",
        additionalData: {
          bloc_name,
        },
      });
    });
  }

  cellH754() {
    const nodes = this.componentHost.querySelectorAll(
      ".callout-container-api .callout-section ul li",
    );

    if (!nodes.length) return;

    nodes.forEach((node, index) => {
      const title = node
        .querySelector(".cmp-teaser__pretitle")
        ?.innerText?.trim()
        .toLowerCase();

      if (!title) return;

      handleGaTracking({
        node,
        eventName: "menu_inpage",
        additionalData: {
          bloc_name: "caroussel explore more",
          bloc_interaction: title,
          bloc_type: index + 1,
        },
      });
    });
  }
}

new TPL_Property_Dining_Breakfast_Bar_Details(document.body);
