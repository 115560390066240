import {
  getDataLayer,
  handleGaTracking,
  extractRidFromBrandUrl,
  extractOfferNameFromUrl,
} from "../../utils/js/tracking.ts";

class TPL_Brand_Offer_Details {
  constructor(componentHost) {
    this.datalayer = getDataLayer();
    if (this.datalayer.pageName !== "mgallery::offers::details") return;

    this.componentHost = componentHost;
    this.initEvents();
  }

  initEvents() {
    this.cellK122();
    this.cellN122();
    this.cellQ122();
    this.cellT122();
    this.cellW122();
    this.cellZ122();
  }

  cellK122() {
    const nodes = this.componentHost.querySelectorAll(
      ".callout-container-api .callout-section ul li",
    );

    nodes.forEach((node, index) => {
      const link = node.querySelector("a")?.href;

      if (!link) return;

      handleGaTracking({
        node,
        eventName: "bloc_interact",
        additionalData: {
          bloc_name: "hotels push",
          bloc_interaction: "see hotel page",
          bloc_type: `${extractRidFromBrandUrl(link)} | ${index + 1}`,
        },
      });
    });
  }

  cellN122() {
    const node = this.componentHost.querySelector(
      ".callout-container-api .ace-loyalty__inner button",
    );

    if (!node) return;

    handleGaTracking({
      node,
      eventName: "bloc_interact",
      additionalData: {
        bloc_name: "hotels push",
        bloc_interaction: "see more hotels",
      },
    });
  }

  cellQ122() {
    const nodes = this.componentHost.querySelectorAll(
      ".callout-container .ace-carousel ul li",
    );

    nodes.forEach((node, index) => {
      const link = node.querySelector("a")?.href;

      if (!link) return;

      handleGaTracking({
        node,
        eventName: "bloc_interact",
        additionalData: {
          bloc_name: "others offers",
          bloc_interaction: "see offer page",
          bloc_type: `${extractOfferNameFromUrl(link)} | ${index + 1}`,
        },
      });
    });
  }

  cellT122() {
    const node = this.componentHost.querySelector(
      ".section-background-container .highlight .ace-highlight__content .call-to-action .ace-button a",
    );

    handleGaTracking({
      node,
      eventName: "bloc_interact",
      additionalData: {
        bloc_name: "m moments",
        bloc_interaction: "discover",
      },
    });
  }

  cellW122() {
    const setEventNameBasedOnChipState = (node) => {
      return node.classList.contains("active")
        ? "remove_filter"
        : "apply_filter";
    };

    const nodes = this.componentHost.querySelectorAll(
      ".callout-container-api .ace-chip ul li button",
    );

    nodes.forEach((node) => {
      const chipName = node.dataset.filterTabId.toLowerCase();

      handleGaTracking({
        node,
        eventName: "apply_filter", // will be updated on the fly by functionToEval in the listener's callback
        additionalData: {
          filter_name: "country",
          filter_label: chipName,
        },
        dynamic: {
          keyToUpdate: "eventName",
          functionToEval: setEventNameBasedOnChipState,
        },
      });
    });
  }

  cellZ122() {
    const node = this.componentHost.querySelector(
      ".section-background-container .ace-core-booking-engine button[type='submit']",
    );

    handleGaTracking({
      node,
      eventName: "bloc_interact",
      additionalData: {
        bloc_name: "search",
        bloc_interaction: "see rates",
      },
    });
  }
}

new TPL_Brand_Offer_Details(document.body);
