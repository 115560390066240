import {
  getDataLayer,
  handleGaTracking,
  extractCityNameFromUrl,
} from "../../utils/js/tracking.ts";

class TPL_Brand_Destinations_City {
  constructor(componentHost) {
    this.datalayer = getDataLayer();
    if (this.datalayer.pageName !== "mgallery::destinations::city") return;

    this.componentHost = componentHost;
    this.initEvents();
  }

  initEvents() {
    this.cellB573();
  }

  cellB573() {
    const nodes = this.componentHost.querySelectorAll(
      ".quick-access nav ul li a",
    );

    nodes?.forEach((node) => {
      const link = node.href;

      if (!link) return;

      handleGaTracking({
        node,
        eventName: "bloc_interact",
        additionalData: {
          bloc_name: "city",
          menu_label: extractCityNameFromUrl(link),
        },
      });
    });
  }
}

new TPL_Brand_Destinations_City(document.body);
