/**
 * Class representing a Mgallery Callout Container Component instance
 * @author Rishabh
 * @class MGalleryCalloutContainer
 * @classdesc Mgallery callout container component to inherit the callout container core component
 * @extends CalloutContainer
 * @version 1.0
 */
import { CalloutContainer, TagManager } from "@accor/ace-ui-core";

export default class MGalleryCalloutContainer extends CalloutContainer {
  /** @inheritDoc */
  // Constructor function that takes the componentHost parameter
  constructor(componentHost) {
    // Call the constructor of the parent class
    super(componentHost);

    this.callCalloutBookingEngine();
  }

  updateFormBookingeEngineValues(id = null, value = "") {
    if (!id || value == "") {
      return;
    }

    if (
      document.querySelector(
        '.ace-core-booking-engine input[name="search.destination"]',
      )
    ) {
      document.querySelector(
        '.ace-core-booking-engine input[name="search.destination"]',
      ).value = id;
    }
    if (
      document.querySelector(
        ".ace-core-booking-engine #search-destination input",
      )
    ) {
      document.querySelector(
        ".ace-core-booking-engine #search-destination input",
      ).value = value;
    }

    // scroll to form
  }

  getDestinationId(element = null) {
    if (!element) {
      return;
    }

    const closetElement = element.closest(".ace-callout-component");

    if (closetElement.dataset.productId) {
      return closetElement.dataset.productId;
    }
  }

  callCalloutBookingEngine() {
    if (
      document.querySelectorAll(".ace-callout-booking-engine-prefilled").length
    ) {
      this.componentHost
        ?.querySelectorAll(".ace-callout-booking-engine-prefilled")
        .forEach((element) => {
          element.addEventListener(CoreJS.DomEventConstants.CLICK, (event) => {
            // get text + id
            event.preventDefault();
            this.updateFormBookingeEngineValues(
              this.getDestinationId(event.target),
              event.target.innerText || "",
            );
            // update booking engine form
            if (
              window.innerWidth >=
              CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg
            ) {
              document
                .querySelector(
                  ".immersive-heading, .sticky-booking-engine, .booking-engine-container",
                )
                .scrollIntoView({ behavior: "smooth", block: "end" });
            } else {
              if (document.querySelector(".sticky-booking-engine")) {
                document
                  .querySelector(".sticky-booking-engine .searching")
                  .click();
              } else {
                document
                  .querySelector(".booking-engine-container")
                  .scrollIntoView({ behavior: "smooth", block: "end" });
              }
            }
            document
              .querySelector(".ace-core-booking-engine [data-date='checkin']")
              .show();
          });
        });
    }
  }
}

// Register the callout container component with the CoreJS.BaseComponent
CoreJS.BaseComponent.registerComponent(
  CalloutContainer.CLASS_NAMESPACE,
  MGalleryCalloutContainer,
  true,
);
