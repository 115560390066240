import { getDataLayer, handleGaTracking } from "../../utils/js/tracking.ts";

class TPL_Property_Homepage {
  constructor(componentHost) {
    this.datalayer = getDataLayer();
    if (this.datalayer.pageName !== "mgallery::hotels::homepage") return;

    this.componentHost = componentHost;
    this.initEvents();
  }

  initEvents() {
    this.cellK420();
    this.cellN420();
  }

  cellK420() {
    const nodes = this.componentHost.querySelectorAll(
      ".callout-container-api .ace-button a",
    );

    if (!nodes.length) return;

    nodes.forEach((node) => {
      let bloc_interaction;

      if (node.classList.contains("cmp-button--primary")) {
        bloc_interaction = "check rates";
        console.log("check rates spotted");
      } else if (node.classList.contains("cmp-teaser__link")) {
        bloc_interaction = "see details";
        console.log("see details spotted");
      }

      handleGaTracking({
        node,
        eventName: "bloc_interact",
        additionalData: {
          bloc_name: "hotel information",
          bloc_interaction,
        },
      });
    });
  }

  cellN420() {
    const node = this.componentHost.querySelector(
      ".section-background-container .highlight .call-to-action a",
    );

    if (!node) return;

    handleGaTracking({
      node,
      eventName: "bloc_interact",
      additionalData: {
        bloc_name: "content m moments",
        bloc_interaction: "discover this m moment",
      },
    });
  }
}

new TPL_Property_Homepage(document.body);
