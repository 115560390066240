export default class MGalleryBookingMice {
  static CLASS_NAMESPACE = "booking-mice";

  constructor() {
    this.stickyElement = document.querySelector(".ace-booking-mice");

    // Initialize the sticky functionality
    this.initialize();
  }

  /** @inheritDoc */
  // Initialize Function
  initialize() {
    this.stickyElementOffset = this.stickyElement?.offsetTop;
    
    const handleResize = () => {
      if (window.innerWidth >= CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg) {
        window.addEventListener("scroll", this.handleScroll.bind(this));
      }
    };
    handleResize();

    window.addEventListener("resize", handleResize);
  }

  // Handle scroll events
  handleScroll() {
    if (!this.stickyElement) {
      return;
    }

    if (window.scrollY >= this.stickyElementOffset) {
      this.stickyElement.classList.add("sticky");
    } else {
      this.stickyElement.classList.remove("sticky");
    }
  }
}

// Register the BookingMice component with the BaseComponent
CoreJS.BaseComponent.registerComponent(
  MGalleryBookingMice.CLASS_NAMESPACE,
  MGalleryBookingMice,
  true,
);
