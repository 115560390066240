import { getDataLayer, handleGaTracking } from "../../utils/js/tracking.ts";

class TPL_Property_Wellness_Detail {
  constructor(componentHost) {
    this.datalayer = getDataLayer();
    if (
      this.datalayer.pageName !== "mgallery::hotels::wellness::spa" &&
      this.datalayer.pageName !== "mgallery::hotels::wellness::pool" &&
      this.datalayer.pageName !== "mgallery::hotels::wellness::fitness"
    )
      return;

    this.componentHost = componentHost;
    this.initEvents();
  }

  initEvents() {
    this.cellE678();
    this.cellH678();
  }

  cellE678() {
    const base = this.componentHost.querySelector(
      ".location .ace-map__content-details",
    );

    if (!base) return;

    const nodes = base.querySelectorAll(
      ".ace-map__content-location-detail, .ace-map__content-reservation-tel, .ace-map__content-reservation-mail, .ace-map__content-parking",
    );

    if (!nodes.length) return;

    nodes.forEach((node) => {
      let bloc_name;
      if (node.classList.contains("ace-map__content-location-detail")) {
        bloc_name = "adress";
      } else if (node.classList.contains("ace-map__content-reservation-tel")) {
        bloc_name = "phone";
      } else if (node.classList.contains("ace-map__content-reservation-mail")) {
        bloc_name = "mail";
      } else if (node.classList.contains("ace-map__content-parking")) {
        bloc_name = "parking";
      }

      if (!bloc_name) return;

      handleGaTracking({
        node,
        eventName: "menu_inpage",
        additionalData: {
          bloc_name,
        },
      });
    });
  }

  cellH678() {
    const base = this.componentHost.querySelector(
      ".product-booking .product-booking-info .product-booking-actions",
    );
    const nodeBook = base?.querySelector(".cmp-button--primary");
    const nodeDiscover = base?.querySelector(".cmp-button--tertiary");

    if (nodeBook) {
      handleGaTracking({
        node: nodeBook,
        eventName: "bloc_interact",
        additionalData: {
          bloc_name: "hotel information",
          bloc_interaction: "book now",
        },
      });
    }

    if (nodeDiscover) {
      handleGaTracking({
        node: nodeDiscover,
        eventName: "bloc_interact",
        additionalData: {
          bloc_name: "hotel information",
          bloc_interaction: "spa menu",
        },
      });
    }
  }
}

new TPL_Property_Wellness_Detail(document.body);
