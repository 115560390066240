export default class MGalleryLoyalty extends CoreJS.BaseComponent {
  static CLASS_NAMESPACE = "loyalty";

  /** @inheritDoc */
  // Constructor function that takes the componentHost parameter
  constructor(componentHost, componentName) {
    // Call the constructor of the parent class (BaseComponent)
    super(componentHost, componentName);
  }
  /** @inheritDoc */
  // Initialize Function
  initialize() {
    super.initialize();
    this.loyaltyContainer = document.querySelector(".loyalty");
    if (!this.loyaltyContainer) {
      return;
    }

    this.oacAllCookie = CoreJS.CommonUtils.userLoginStatus();
    this.toogleLoyalty();
  }

  /**
   * Based on the User login we are displaying the loyalty
   */
  toogleLoyalty() {
    this.loyaltyContainer.style.display = this.oacAllCookie ? "none" : "block";
  }
}

// Register the Loyalty component with the CoreJS.BaseComponent
CoreJS.BaseComponent.registerComponent(
  MGalleryLoyalty.CLASS_NAMESPACE,
  MGalleryLoyalty,
  true,
);
