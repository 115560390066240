import {
  getDataLayer,
  handleGaTracking,
  extractFilterNameFromAnchor,
  extractRidFromBrandUrl,
  extractCityNameFromUrl,
} from "../../utils/js/tracking.ts";

class TPL_Brand_Hotel {
  constructor(componentHost) {
    this.datalayer = getDataLayer();
    if (this.datalayer.pageName !== "mgallery::destinations::world") return;

    this.componentHost = componentHost;

    this.calloutContainers = this.componentHost.querySelectorAll(
      ".callout-container-api",
    );

    this.initEvents();
  }

  initEvents() {
    this.cellK65();
    // this.cellN65();
    this.cellT65();
    this.cellW65();
    this.cellZ65();
    this.cellAC65();
  }

  cellK65() {
    const nodes = this.componentHost.querySelectorAll(
      ".experience-fragment .card-container ul li a",
    );

    if (!nodes.length) return;

    nodes.forEach((node) => {
      handleGaTracking({
        node,
        eventName: "bloc_interact",
        additionalData: {
          bloc_name: "destinations",
          bloc_interaction: "see destination page",
          bloc_type: extractCityNameFromUrl(node.href),
        },
      });
    });
  }

  cellN65() {
    // const nodes =
    // @TODO map à faire fonctionner en local (toute une épopée, à voir en dernier)
  }

  cellT65() {
    const nodes = this.componentHost.querySelectorAll(
      ".quick-access nav ul li",
    );

    if (!nodes.length) return;

    nodes.forEach((node, index) => {
      const anchor = node.querySelector("a").href;

      handleGaTracking({
        node,
        eventName: "menu_inpage",
        additionalData: {
          bloc_name: "continent localisation",
          menu_position: index + 1,
          menu_label: extractFilterNameFromAnchor(anchor),
        },
      });
    });
  }

  cellW65() {
    const setEventNameBasedOnChipState = (node) => {
      return node.classList.contains("active")
        ? "remove_filter"
        : "apply_filter";
    };

    this.calloutContainers?.forEach((container) => {
      const nodes = container.querySelectorAll(".ace-chip ul li button");
      const continent = container
        .closest(".acc-container")
        ?.querySelector(".title .ace-shorttitle")
        ?.id?.toLowerCase();

      nodes.forEach((node) => {
        const chipName = node.dataset.filterTabId?.toLowerCase();

        if (!chipName) return;

        handleGaTracking({
          node,
          eventName: "apply_filter", // will be updated on the fly by functionToEval in the listener callback
          additionalData: {
            filter_name: "country",
            filter_label: `${continent} - ${chipName}`,
          },
          dynamic: {
            keyToUpdate: "eventName",
            functionToEval: setEventNameBasedOnChipState,
          },
        });
      });
    });
  }

  cellZ65() {
    this.calloutContainers?.forEach((container) => {
      const nodes = container.querySelectorAll(".callout-section ul li");

      if (!nodes.length) return;

      nodes.forEach((node, index) => {
        const link = node.querySelector("a")?.href;

        if (!link) return;

        handleGaTracking({
          node,
          eventName: "bloc_interact",
          additionalData: {
            bloc_name: "hotels push",
            bloc_interaction: "see hotel page",
            bloc_type: `${extractRidFromBrandUrl(link)}|${index + 1}`,
          },
        });
      });
    });
  }

  cellAC65() {
    this.calloutContainers?.forEach((container) => {
      const node = container.querySelector(".ace-loyalty__inner button"); // lol, yes, this is a "show more" button

      if (!node) return;

      handleGaTracking({
        node,
        eventName: "bloc_interact",
        additionalData: {
          bloc_name: "hotels push",
          bloc_interaction: "see more hotels",
        },
      });
    });
  }
}

new TPL_Brand_Hotel(document.body);
