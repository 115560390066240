import {
  getDataLayer,
  handleGaTracking,
  extractFilterNameFromAnchor,
} from "../../utils/js/tracking";

class TPL_Brand_M_Moment_Hub {
  constructor(componentHost) {
    this.datalayer = getDataLayer();
    if (
      this.datalayer.pageName !==
      "mgallery::mgalleryuniverse::memorable::mmoments"
    )
      return;

    this.componentHost = componentHost;

    this.calloutContainers =
      this.componentHost.querySelectorAll(".callout-container");

    this.initEvents();
  }

  initEvents() {
    this.cellB259();
    this.cellE259();
    this.cellH259();
    this.cellK259();
  }

  cellB259() {
    if (!this.calloutContainers.length) return;

    const setEventNameBasedOnChipState = (node) => {
      return node.classList.contains("active")
        ? "remove_filter"
        : "apply_filter";
    };

    this.calloutContainers.forEach((callout) => {
      const nodes = callout.querySelectorAll("ul.ace-chip__list li button");

      if (!nodes.length) return;

      nodes.forEach((node) => {
        const thematic = node.dataset?.filterTabId;
        const continent = this.getContinent(node);

        handleGaTracking({
          node,
          eventName: "apply_filter", // <- will be updated on the fly
          additionalData: {
            bloc_name: continent,
            filter_label: thematic,
            filter_name: "thematic menu",
          },
          dynamic: {
            keyToUpdate: "eventName",
            functionToEval: setEventNameBasedOnChipState,
          },
        });
      });
    });
  }

  cellE259() {
    const nodes = this.componentHost.querySelectorAll(".quick-access ul li a");

    if (!nodes.length) return;

    nodes.forEach((node, index) => {
      handleGaTracking({
        node,
        eventName: "menu_inpage",
        additionalData: {
          bloc_name: "continent",
          menu_label: extractFilterNameFromAnchor(node.href),
          menu_position: index + 1,
        },
      });
    });
  }

  cellH259() {
    if (!this.calloutContainers.length) return;

    this.calloutContainers.forEach((calloutContainer) => {
      const nodes = calloutContainer.querySelectorAll(".callout-section ul li");

      if (!nodes.length) return;

      nodes.forEach((node, index) => {
        const continent = this.getContinent(node);

        handleGaTracking({
          node,
          eventName: "bloc_interact",
          additionalData: {
            bloc_name: `editorial ${continent}`,
            bloc_interaction: "see editorial page",
            bloc_type: index + 1,
          },
        });
      });
    });
  }

  cellK259() {
    const nodes = this.componentHost.querySelectorAll(".ace-guest-viewmore");

    if (!nodes.length) return;

    nodes.forEach((node) => {
      const continent = this.getContinent(node);

      handleGaTracking({
        node,
        eventName: "bloc_interact",
        additionalData: {
          bloc_name: `editorial ${continent}`,
          bloc_interaction: "see more editorials",
        },
      });
    });
  }

  getContinent(node) {
    return node
      .closest(".acc-container")
      ?.querySelector(".title")
      .firstElementChild?.id?.toLowerCase();
  }
}

new TPL_Brand_M_Moment_Hub(document.body);
