import { TagManager } from "@accor/ace-ui-core";

export default class SpecialTouches extends CoreJS.BaseComponent {
  static CLASS_NAMESPACE = "ace-special-touches";

  /** @inheritDoc */
  constructor(componentHost, componentName) {
    super(componentHost, componentName);
  }

  /** @inheritDoc */
  initialize() {
    super.initialize();
    this.initializeContentStructure();
    this.componentHost
      .querySelectorAll(".content-wrapper .content-column")
      .forEach((element, index) => {
        const videoItem = element.querySelector("video");
        if (!videoItem) return;
        const videoItemCloned = videoItem.cloneNode(true);
        this.componentHost
          ?.querySelector(
            `.content-background-media .content-${parseInt(index + 1)}`,
          )
          .appendChild(videoItemCloned);
        videoItem.parentNode.removeChild(videoItem);
      });

    // We don't want to enable all JS in edit to facilitate contribution of component
    if (!this.componentHost.classList.contains("edit-mode")) {
      this.activeVideo = null;
      this.activeElement = null;

      this.componentHost
        .querySelectorAll(".fake-content-wrapper .content-column")
        .forEach((element) => {
          this.bindItemEventsDependingOnBreakpoints(element);
        });

      this.bindGlobalEventDependingOnBreakpoints();
      document.dispatchEvent(new CustomEvent("loadvideoevent"));

      this.activateElement(
        this.componentHost.querySelector(
          ".fake-content-wrapper .content-column",
        ),
      );

      //Make all tabs inactive to display only the title on page load
      this.deactivateContentColumn(
        this.componentHost.querySelectorAll(".content-column.active"),
      );
    }

    this._mediaContainerVal = this.componentHost.querySelector(
      ".content-background-media",
    );
    this._colContainerVal =
      this.componentHost.querySelector(".content-wrapper");

    const containerImage =
      this.componentHost.querySelectorAll(".image-container");
    const containerTarget = this.componentHost.querySelectorAll(
      ".js-content-container",
    );

    const isImage = containerImage.length !== 0;

    if (isImage) {
      for (let i = 0; i < containerImage.length; i++) {
        containerTarget[i].appendChild(
          containerImage[i].querySelector(".ace-image"),
        );
        // set dynamic height in case of image media type on component
        this.componentHost?.classList?.add("has-image");
      }
    }

    this.handleSwipingMethod();
    window.addEventListener("resize", this.handleSwipingMethod);

    this.checkForActiveItemMediaType();

    this.handleHovering();
  }

  handleSwipingMethod() {
    if (window.innerWidth < CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg) {
      if (this.isFourTabs()) {
        this.fourTabsSwipeEvent();
      } else {
        this.mobileSwipeEvent();
      }
    }
  }

  handleHovering() {
    const realContainer = this.componentHost.querySelector(".content-wrapper");
    const fakeContainer = this.componentHost.querySelector(
      ".fake-content-wrapper",
    );

    const realContent = this.componentHost.querySelectorAll(
      ".content-wrapper .content-column",
    );
    const fakeContent = this.componentHost.querySelectorAll(
      ".fake-content-wrapper .content-column",
    );

    fakeContainer.addEventListener("mouseenter", () => {
      fakeContainer.classList.add("hovered");
      realContainer.classList.add("hovered");
    });

    fakeContainer.addEventListener("mouseleave", () => {
      fakeContainer.classList.remove("hovered");
      realContainer.classList.remove("hovered");
    });

    fakeContent.forEach((fakeItem, index) => {
      fakeItem.addEventListener("mouseenter", () => {
        fakeContent[index]?.classList.add("hovered");
        realContent[index]?.classList.add("hovered");
      });

      fakeItem.addEventListener("mouseleave", () => {
        fakeContent[index]?.classList.remove("hovered");
        realContent[index]?.classList.remove("hovered");
      });
    });
  }

  deactivateContentColumn(elements) {
    elements.forEach((element) => element.classList.remove("active"));
  }

  isFourTabs() {
    return this.componentHost.classList.contains("ace-special-touches-4x");
  }

  fourTabsSwipeEvent() {
    this._active = 1;
    const totalItems = 4;
    const controls = this.componentHost.querySelector(
      ".special-touches-actions",
    );
    const previousButton = controls.querySelector(".left");
    const nextButton = controls.querySelector(".right");
    const paginationDisplaySwipe = controls.querySelector(".counter");

    const total = totalItems;

    paginationDisplaySwipe.innerText = `${this._active} / ${total}`;
    previousButton.disabled = true;

    const thresholdDefinition = {
      threshold: 0.6,
    };
    this.specialTouchObserver = new IntersectionObserver((item) => {
      item.forEach((entry) => {
        if (entry.isIntersecting) {
          if (
            window.innerWidth < CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg
          ) {
            this._active =
              parseInt(entry.target.getAttribute("display-id")) + 1;
            if (this._active === 1) previousButton.disabled = true;
            if (this._active < total) nextButton.disabled = null;
            if (this._active > 1) previousButton.disabled = null;
            if (this._active === total) nextButton.disabled = true;
            paginationDisplaySwipe.innerText = `${this._active} / ${total}`;
            if (this._active !== total)
              this.deactivateElement(
                this.componentHost.querySelector(
                  `.content-column[data-number='${this._active + 1}']`,
                ),
              );
            if (this._active !== 1)
              this.deactivateElement(
                this.componentHost.querySelector(
                  `.content-column[data-number='${this._active - 1}']`,
                ),
              );
            this.activateElement(
              this.componentHost.querySelector(
                `.content-column[data-number='${this._active}']`,
              ),
            );
          }
        }
      });
    }, thresholdDefinition);
    const itemList = this._colContainerVal.querySelectorAll(".content-column");
    itemList?.forEach((item, index) => {
      item.setAttribute("display-id", index);
      this.specialTouchObserver?.observe(item);
      if (window.innerWidth < CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg) {
        item
          ?.querySelector(".special-touches-item a")
          ?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
            const dataLayerValue = document
              .querySelector("[data-cmp-datalayer]")
              ?.getAttribute("data-cmp-datalayer");
            if (dataLayerValue) {
              this.dataLayerJson = JSON.parse(dataLayerValue);
              this.tagManagerhandler(
                item?.querySelector(".special-touches-item a"),
              );
            }
          });
      }
    });
  }

  mobileSwipeEvent() {
    this._active = 1;
    const total = this.componentHost.querySelectorAll(
      ".content-viewer-component",
    ).length;
    const controls = this.componentHost.querySelector(
      ".special-touches-actions",
    );
    const previousButton = controls.querySelector(".left");
    const nextButton = controls.querySelector(".right");
    const paginationDisplaySwipe = controls.querySelector(".counter");

    paginationDisplaySwipe.innerText = `${this._active} / ${total}`;
    previousButton.disabled = true;

    const thresholdDefinition = {
      threshold: 0.6,
    };
    this.specialTouchObserver = new IntersectionObserver((item) => {
      item.forEach((entry) => {
        if (entry.isIntersecting) {
          if (
            window.innerWidth < CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg
          ) {
            this._active =
              parseInt(entry.target.getAttribute("display-id")) + 1;
            if (this._active === 1) previousButton.disabled = true;
            if (this._active < total) nextButton.disabled = null;
            if (this._active > 1) previousButton.disabled = null;
            if (this._active === total) nextButton.disabled = true;
            paginationDisplaySwipe.innerText = `${this._active} / ${total}`;
            if (this._active !== total)
              this.deactivateElement(
                this.componentHost.querySelector(
                  `.content-column[data-number='${this._active + 1}']`,
                ),
              );
            if (this._active !== 1)
              this.deactivateElement(
                this.componentHost.querySelector(
                  `.content-column[data-number='${this._active - 1}']`,
                ),
              );
            this.activateElement(
              this.componentHost.querySelector(
                `.content-column[data-number='${this._active}']`,
              ),
            );
          }
        }
      });
    }, thresholdDefinition);
    const itemList = this._colContainerVal.querySelectorAll(".content-column");
    itemList?.forEach((item, index) => {
      item.setAttribute("display-id", index);
      this.specialTouchObserver?.observe(item);
      if (window.innerWidth < CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg) {
        item
          ?.querySelector(".special-touches-item a")
          ?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
            const dataLayerValue = document
              .querySelector("[data-cmp-datalayer]")
              ?.getAttribute("data-cmp-datalayer");
            if (dataLayerValue) {
              this.dataLayerJson = JSON.parse(dataLayerValue);
              this.tagManagerhandler(
                item?.querySelector(".special-touches-item a"),
              );
            }
          });
      }
    });
  }

  initializeContentStructure() {
    // First find containers with ace-special-touches-2x class
    const specialTouches2xContainers = document.querySelectorAll(
      ".ace-special-touches",
    );

    specialTouches2xContainers.forEach((container) => {
      // Then find special-touches-items within these containers
      const specialTouchesItems = container.querySelectorAll(
        ".special-touches-item",
      );

      specialTouchesItems.forEach((item) => {
        if (!item.dataset.restructured) {
          const h2 = item.querySelector("h2");
          const showHideContent = item.querySelector(".show-hide-content");

          if (h2 && showHideContent) {
            /* Clone h2 and add it before show-hide-content
             this wil behave as the main title before the mouse over */
            const h2Clone = h2.cloneNode(true);
            item.insertBefore(h2Clone, showHideContent);

            // Store all the original content
            const originalContent = Array.from(showHideContent.children);

            // Create new wrapper with same classes and style
            const newWrapper = document.createElement("div");
            newWrapper.className = showHideContent.className;
            newWrapper.style.height = showHideContent.style.height;

            // Move original h2 into the new wrapper
            newWrapper.appendChild(h2);

            // Move the paragraph and other content, excluding links temporarily
            originalContent.forEach((child) => {
              if (child.tagName !== "A" && child.tagName !== "H2") {
                newWrapper.appendChild(child.cloneNode(true));
              }
            });

            // Find and append only the last link
            const links = Array.from(
              showHideContent.querySelectorAll("a.link"),
            );
            if (links.length > 0) {
              newWrapper.appendChild(links[links.length - 1].cloneNode(true));
            }

            // Replace old show-hide-content with new wrapper
            showHideContent.parentNode.replaceChild(
              newWrapper,
              showHideContent,
            );

            // Mark as restructured
            item.dataset.restructured = "true";
          }
        }
      });
    });
  }

  /**
   * Binding Events which are global to component. It's mainly to add controls of the carousel view in tablet
   * and mobile.
   */
  bindGlobalEventDependingOnBreakpoints() {
    if (window.innerWidth >= CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg) {
      // Do something on desktop, but nothing for now
    } else {
      const controls = this.componentHost.querySelector(
        ".special-touches-actions",
      );
      const previousButton = controls.querySelector(".left");
      const nextButton = controls.querySelector(".right");

      previousButton.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
        this._mediaContainerVal.scrollLeft -=
          this._mediaContainerVal.querySelector(
            ".media-container",
          )?.offsetWidth;
        this._colContainerVal.scrollLeft -=
          this._colContainerVal.querySelector(".content-column")?.offsetWidth;
      });

      nextButton.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
        this._mediaContainerVal.scrollLeft +=
          this._mediaContainerVal.querySelector(
            ".media-container",
          )?.offsetWidth;
        this._colContainerVal.scrollLeft +=
          this._colContainerVal.querySelector(".content-column")?.offsetWidth;
      });
    }
  }

  /**
   * Events which are specific to each Special touches items. It's mainly handling desktop view when user interacts
   * independently on one column.
   * @param {HTMLElement} htmlElement - Current item in iteration
   */
  bindItemEventsDependingOnBreakpoints(htmlElement) {
    // We retrieved the default video active.
    const associatedVideo = this.componentHost.querySelector(
      `.content-background-media div.content-${htmlElement.dataset.number}`,
    );
    if (htmlElement.classList.contains("active")) {
      this.activeVideo = associatedVideo;
    }

    const associatedColumn = this.componentHost.querySelector(
      `.associated-item-${htmlElement.dataset.number}`,
    );
    const specialTouchItem = associatedColumn.querySelector(
      ".special-touches-item",
    );
    specialTouchItem.dataset.originalHeight = specialTouchItem.clientHeight;
    const showHideContent =
      associatedColumn.querySelector(".show-hide-content");

    // Binding Event for Desktop view
    if (window.innerWidth > CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.md) {
      // We need to hide text column height by JS to be able to retrieve its original height for the rollover effect.
      showHideContent.classList.add("no-height");
      [
        CoreJS.DomEventConstants.MOUSE_OVER,
        CoreJS.DomEventConstants.FOCUS_IN,
      ].forEach((event) => {
        htmlElement.addEventListener(event, (overEvent) => {
          // On first mouseover event, we change the whole layout only once
          this.activateElement(overEvent.currentTarget);
        });
        htmlElement.addEventListener("mouseleave", (outEvent) => {
          // On mouseleave, deactivate the element
          this.deactivateElement(outEvent.currentTarget);
        });
      });

      const isNotImage =
        this.componentHost.querySelectorAll(".image-container").length == 0;

      if (isNotImage) {
        htmlElement.addEventListener(
          CoreJS.DomEventConstants.MOUSE_LEAVE,
          () => {
            this.activeVideo.querySelector("video").pause();
          },
        );
      }
    }
    // Binding click event on fake content wrapper, to make the whole column that can be clicked instead of just the CTA link.
    const link = showHideContent.querySelector("a.link");
    if (link) {
      htmlElement.style.cursor = "pointer";
    }

    // eslint-disable-next-line no-extra-semi
    [
      CoreJS.DomEventConstants.CLICK
    ].forEach((eventType) => {
      htmlElement.addEventListener(eventType, () => {
        window.open(link.dataset.link, "_self");
        const dataLayerValue = document
          .querySelector("[data-cmp-datalayer]")
          ?.getAttribute("data-cmp-datalayer");
        if (dataLayerValue) {
          this.dataLayerJson = JSON.parse(dataLayerValue);
          this.tagManagerhandler(link);
        }
      });
    });

    htmlElement.addEventListener(CoreJS.DomEventConstants.TOUCH_START, (e) => {
      e.target.dataset.touchStartX = e.touches[0].clientX;
      e.target.dataset.touchStartY = e.touches[0].clientY;
    });
  
    htmlElement.addEventListener(CoreJS.DomEventConstants.TOUCH_END, (e) => {
      const startX = parseFloat(e.target.dataset.touchStartX);
      const startY = parseFloat(e.target.dataset.touchStartY);
      const endX = e.changedTouches[0].clientX;
      const endY = e.changedTouches[0].clientY;
    
      // Calculate movement distance
      const deltaX = Math.abs(endX - startX);
      const deltaY = Math.abs(endY - startY);
    
      // Define a threshold for swipe detection
      const swipeThreshold = 0;

      const controls = this.componentHost.querySelector(
        ".special-touches-actions",
      );
    
      if (deltaX <= swipeThreshold && deltaY <= swipeThreshold) {
        // It's a tap (click), not a swipe
        window.open(link.dataset.link, "_self");
        const dataLayerValue = document
          .querySelector("[data-cmp-datalayer]")
          ?.getAttribute("data-cmp-datalayer");
        if (dataLayerValue) {
          this.dataLayerJson = JSON.parse(dataLayerValue);
          this.tagManagerhandler(link);
        }

      } else {
        const swipeDetector = 50;
        if(startX - endX > swipeDetector) {
          controls.querySelector(".right").click();
        }else if (endX - startX > swipeDetector) {
          controls.querySelector(".left").click();
        }
      }
    });

    // We now fill all Dynamic Media Video tags with correct Scene7 values.
    this.populateVideoDataAttributesAndLoad(specialTouchItem, associatedVideo);
  }

  /**
   * Deactivate view of an HTML element card of the component
   * @param {HTMLElement} sourceElement -
   */
  deactivateElement(sourceElement) {
    const showHidecontents = document.querySelectorAll(".show-hide-content");
    const associatedColumns = document.querySelectorAll(
      ".content-wrapper .content-column",
    );

    showHidecontents.forEach((item) => {
      item.classList.remove("active");
    });
    associatedColumns.forEach((item) => {
      item.classList.remove("active");
    });
    const associatedColumn = this.componentHost.querySelector(
      `.associated-item-${sourceElement.dataset.number}`,
    );
    associatedColumn.classList.remove("active");

    const associatedColumnDesc =
      associatedColumn.querySelector(".show-hide-content");

    setTimeout(() => {
      associatedColumnDesc.classList.remove("active");
    }, 0);

    const associatedFakeColumn = this.componentHost.querySelector(
      `[data-number="${sourceElement.dataset.number}"]`,
    );

    associatedFakeColumn.classList.remove("active");

    const showHideContent =
      associatedColumn.querySelector(".show-hide-content");
    const comingVideo = this.componentHost.querySelector(
      `.content-background-media div.content-${sourceElement.dataset.number}`,
    );

    if (window.videoViewers) {
      const associatedViewer = window.videoViewers.find(
        (viewer) => viewer.containerId === comingVideo.id,
      );
      if (associatedViewer && comingVideo.querySelector("video")) {
        associatedViewer.videoplayer.pause();
      }
    }

    showHideContent.classList.add("no-height");
    showHideContent.style.height = null;
  }

  /**
   * Activate view of an HTML element card of the component
   * @param {HTMLElement} sourceElement - The Fake column handling
   * @param {Boolean} playVideo -
   */
  activateElement(sourceElement, playVideo = true) {
    if (this.activeElement) {
      this.deactivateElement(this.activeElement);
    }

    this.activeElement = sourceElement;

    const alreadyActiveElement = this.componentHost.querySelector(
      ".content-column.active",
    );

    if (alreadyActiveElement) {
      alreadyActiveElement.classList.remove("active");
    }

    const associatedColumn = this.componentHost.querySelector(
      `.associated-item-${sourceElement.dataset.number}`,
    );

    const associatedColumnDesc =
      associatedColumn.querySelector(".show-hide-content");

    const associatedFakeColumn = this.componentHost.querySelector(
      `[data-number="${sourceElement.dataset.number}"]`,
    );

    const comingVideo = this.componentHost.querySelector(
      `.content-background-media div.content-${sourceElement.dataset.number}`,
    );

    // Toggling 'hidden' class on video holder will trigger automatically CSS transition on opacity.
    this.activeVideo.classList.add("hidden");
    comingVideo.classList.remove("hidden");

    if (playVideo && window.videoViewers) {
      const associatedViewer = window.videoViewers.find(
        (viewer) => viewer.containerId === comingVideo.id,
      );
      if (associatedViewer && comingVideo.querySelector("video")) {
        //  To allow starting play by code, we need to mute video
        comingVideo.querySelector("video").muted = "muted";
        comingVideo.querySelector("video").setAttribute("muted", "");
        comingVideo.querySelector("video").setAttribute("playsinline", "");
        associatedViewer.videoplayer.play();
      }
    }
    this.activeVideo = comingVideo;

    // Once activated check for media type
    this.checkForActiveItemMediaType();
  }

  /**
   * Method making travel original video dataset attributes from column items, to corresponding
   * dynamic media video component.
   * @param {HTMLElement} sourceElement - Source Element carrying video data attributes
   * @param {HTMLElement} targetElement - Target Element Dynamic Media video component using S7 sdk.
   */
  populateVideoDataAttributesAndLoad(sourceElement, targetElement) {
    targetElement.dataset.imageServerUrl = sourceElement.dataset.imageServerUrl;
    targetElement.dataset.videoServerUrl = sourceElement.dataset.videoServerUrl;
    targetElement.dataset.presetPath = sourceElement.dataset.presetPath;
    targetElement.dataset.assetId = sourceElement.dataset.assetId;
    targetElement.classList.remove("deferred");
  }

  /**
   * push ga events for clickable cards in tag manager for all devices
   * check if card is clickable if yes add event handler for corresponding fake wrapper element
   * @param {HTMLElement} link - Target Element for tag manager event
   */
  tagManagerhandler(link) {
    const dataEvent = {
      event: "GA4event",
      eventName: "bloc_interact",
      event_data: {
        pagename: this.dataLayerJson?.pageName,
        bloc_name: "highlighted memorable",
        bloc_interaction: `see ${link?.closest(".special-touches-item")?.querySelector("h2")?.innerText?.trim()?.toLowerCase() || ""}`,
      },
    };
    TagManager.trackEvent(dataEvent);
  }

  /**
   * check media type of active item
   * Based on media type, add class on overlay to set opacity
   */
  checkForActiveItemMediaType() {
    const activeElement = this.componentHost?.querySelector(
      ".content-column.active",
    );
    if (activeElement?.querySelector(".image-container")) {
      this.componentHost
        ?.querySelector(".background-overlays")
        ?.classList?.add("image-overlay");
    } else {
      this.componentHost
        ?.querySelector(".background-overlays")
        ?.classList?.remove("image-overlay");
    }
  }
}

// Registering component in component factory.
CoreJS.BaseComponent.registerComponent(
  SpecialTouches.CLASS_NAMESPACE,
  SpecialTouches,
  true,
);
