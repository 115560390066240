import { getDataLayer, handleGaTracking } from "../../utils/js/tracking.ts";

class TPL_Accomodation_Hub {
  constructor(componentHost) {
    this.datalayer = getDataLayer();
    if (
      this.datalayer.pageName !== "mgallery::hotels::accomodation::hub" &&
      this.datalayer.pageName !== "mgallery::hotels::accomodation::detail"
    )
      return;

    this.componentHost = componentHost;
    this.initEvents();
  }

  initEvents() {
    this.cellE647();
    this.cellH647();
    this.cellK647();
  }

  cellE647() {
    // visible only in PRD
    const nodes = this.componentHost.querySelectorAll(
      ".callout-container-api .ace-callout-container-filters__tab-nav ul li button",
    );

    if (!nodes.length) return;

    nodes.forEach((node, index) => {
      const accomodationType = node.dataset?.filterTabId?.toLowerCase();

      handleGaTracking({
        node,
        eventName: "menu_inpage",
        additionalData: {
          bloc_name: "accomodation",
          menu_position: index + 1,
          menu_label: accomodationType,
        },
      });
    });
  }

  cellH647() {
    const nodes = this.componentHost.querySelectorAll(
      ".callout-container-api:has(.cmp-teaser__link) .ace-button a",
    );

    if (!nodes) return;

    nodes.forEach((node) => {
      let bloc_interaction;
      const accomodationType = node
        .closest("[data-filters]")
        ?.dataset.filters.toLowerCase();

      if (node.classList.contains("cmp-button--primary")) {
        bloc_interaction = "check rates";
      } else if (node.classList.contains("cmp-teaser__link")) {
        bloc_interaction = "see details";
      }

      handleGaTracking({
        node,
        eventName: "bloc_interact",
        additionalData: {
          bloc_name: "hotel information",
          bloc_interaction,
          bloc_type: accomodationType,
        },
      });
    });
  }

  cellK647() {
    const node = this.componentHost.querySelector(".ace-guest-viewmore");

    if (!node) return;

    handleGaTracking({
      node,
      eventName: "bloc_interact",
      additionalData: {
        bloc_name: "hotel information",
        bloc_interaction: "see more",
      },
    });
  }
}

new TPL_Accomodation_Hub(document.body);
