import { getDataLayer, handleGaTracking } from "../../utils/js/tracking";

class TPL_Brand_Loyalty {
  constructor(componentHost) {
    this.datalayer = getDataLayer();
    if (this.datalayer.pageName !== "mgallery::brand::loyalty") return;

    this.componentHost = componentHost;
    this.initEvents();
  }

  initEvents() {
    this.cellB296();
  }

  cellB296() {
    const nodes = this.componentHost.querySelectorAll(".button .cmp-button");

    nodes.forEach((node) => {
      handleGaTracking({
        node,
        eventName: "cta_enroll",
        additionalData: {
          enroll_context: "loyalty",
          cta_name: "join now",
        },
      });
    });
  }
}

new TPL_Brand_Loyalty(document.body);
