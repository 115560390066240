import { TagManager } from "@accor/ace-ui-core";

const getDataLayer = (): Record<string, string> => {
  const dataLayerAttr = "data-cmp-datalayer";

  const dataLayer = document
    ?.querySelector(`[${dataLayerAttr}]`)
    ?.getAttribute(dataLayerAttr);

  return dataLayer ? JSON.parse(dataLayer) : {};
};

interface EventTrackingInputs {
  node: Node | null;
  htmlEventType: string;
  eventName: string;
  additionalData?: Record<string, any>;
  dynamic?: Record<string, any>;
}

const handleGaTracking = ({
  node,
  htmlEventType = CoreJS.DomEventConstants.CLICK,
  eventName,
  additionalData = {},
  dynamic = {},
}: EventTrackingInputs) => {
  const dataLayer = getDataLayer();

  if (!dataLayer || !node) return;

  node.addEventListener(htmlEventType, () => {
    if (dynamic.keyToUpdate) {
      if (dynamic.keyToUpdate === "eventName") {
        eventName = dynamic.functionToEval(node);
      } else {
        // ex: case additionnalData.filter_name
        additionalData[dynamic.keyToUpdate] =
          `${dynamic.staticValue ? dynamic.staticValue : ""}${dynamic.functionToEval(node)}`;
      }
    }

    const eventData = {
      event: "GA4event",
      eventName,
      event_data: {
        pagename: dataLayer.pageName,
        ...additionalData,
      },
    };

    TagManager.trackEvent(eventData);
  });
};

const currentComponentIsNextTo = (
  currentComponent: HTMLElement,
  brotherClass: string,
) =>
  currentComponent.previousElementSibling?.classList.contains(brotherClass) ||
  false;

const currentComponentIsChildOf = (
  currentComponent: HTMLElement,
  parentClass: string,
): boolean => (currentComponent.closest(`.${parentClass}`) ? true : false);

const extractRidFromBrandUrl = (hrefString: string): string =>
  hrefString?.match(/hotels\/([^.]+)\.html/)?.[1] ||
  "bad link format (to get RID from brand url)";

const extractRidFromAllUrl = (hrefString: string): string =>
  hrefString?.match(/\/rates\/([^/]+)\//)?.[1] ||
  "bad link format (to get RID from all url)";

const extractOfferNameFromUrl = (hrefString: string): string =>
  hrefString?.match(/offers\/([^.]+)\.html/)?.[1] ||
  "bad link format (to get offer name)";

const extractFilterNameFromAnchor = (anchor: string): string =>
  anchor?.split("#")[1]?.toLowerCase() || "bad anchor format";

const extractRidFromBookingMiceUrl = (htmlString: string): string =>
  htmlString?.match(/hotel_code=([A-Z0-9]+)/)?.[1] ||
  "bad link format (to get RID from mice url)";

const extractTourGuidePageNameFromUrl = (hrefString: string): string =>
  hrefString?.match(/tour-guide\/([^.]+)\.html/)?.[1] ||
  "bad link format (to get tour-guide name)";

const extractCountryNameFromUrl = (hrefString: string): string =>
  hrefString?.match(/countries\/([^.]+)\.html/)?.[1] ||
  "bad link format (to get country name)";

const extractCityNameFromUrl = (hrefString: string): string =>
  hrefString?.match(/cities\/([^.]+)\.html/)?.[1] ||
  "bad link format (to get city name)";

const extractWellnessTypeFromUrl = (hrefString: string): string =>
  hrefString?.match(/wellness-([^.]+)/)?.[1] ||
  "bad link format (to get city name)";

const extractMeetingsOrWeddingsFromUrl = (hrefString: string): string => {
  if (hrefString.includes("meeting")) {
    return "meetings";
  } else if (hrefString.includes("wedding")) {
    return "weddings";
  } else {
    return "bad link format (to get celebration type)";
  }
};

export {
  getDataLayer,
  handleGaTracking,
  currentComponentIsNextTo,
  currentComponentIsChildOf,
  extractRidFromBrandUrl,
  extractRidFromAllUrl,
  extractOfferNameFromUrl,
  extractFilterNameFromAnchor,
  extractRidFromBookingMiceUrl,
  extractTourGuidePageNameFromUrl,
  extractCountryNameFromUrl,
  extractCityNameFromUrl,
  extractWellnessTypeFromUrl,
  extractMeetingsOrWeddingsFromUrl,
};
