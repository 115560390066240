class MenuModoPatch {
  constructor() {
    this.initialize();
  }

  initialize() {
    this.currentPageName = this.getCurrentPageName();
    this.langMenuHasBeenOpened = false;

    console.log("hey menulodopatch");

    // only these pages are concerned by menumodo's bug
    if (
      this.currentPageName === "mgallery::hotels::wellness::spa" ||
      this.currentPageName === "mgallery::hotels::wellness::pool" ||
      this.currentPageName === "mgallery::hotels::wellness::fitness"
    ) {
      this.doPatch();
    }
  }

  getCurrentPageName() {
    const dataLayerString = document
      ?.querySelector("[data-cmp-datalayer]")
      ?.getAttribute("data-cmp-datalayer");

    return JSON.parse(dataLayerString).pageName;
  }

  doPatch() {
    // the patch consist of a mutation observer on the document.body
    const config = { attributes: true, childList: false, subtree: false };

    // that call this function
    const mutationCallback = (mutationList, observer) => {
      for (const mutation of mutationList) {
        if (mutation.type !== "attributes") {
          return;
        }
        // watching to see this attributes
        if (mutation.attributeName === "data-bs-overflow") {
          if (!this.langMenuHasBeenOpened) {
            this.langMenuHasBeenOpened = true;
            console.log("langMenuHasBeenOpened is true now");
          } else {
            // when it's the second time
            // set unset values
            document.body.style.overflow = "";
            document.body.style.paddingRight = "";
          }
        }
      }
    };

    const observer = new MutationObserver(mutationCallback);

    observer.observe(document.body, config);
  }
}

new MenuModoPatch();
