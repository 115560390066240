import {
  getDataLayer,
  handleGaTracking,
  extractRidFromBookingMiceUrl,
} from "../../utils/js/tracking.ts";

class TPL_Property_Weeding {
  constructor(componentHost) {
    this.datalayer = getDataLayer();
    if (this.datalayer.pageName !== "mgallery::hotels::weddings") return;

    this.componentHost = componentHost;
    this.initEvents();
  }

  initEvents() {
    this.cellB495();
  }

  cellB495() {
    const node = this.componentHost.querySelector(
      ".booking-mice .booking-mice__link",
    );

    console.log("booking mice href : ", node.href);

    handleGaTracking({
      node,
      eventName: "bloc_interact",
      additionalData: {
        bloc_name: "make an event enquiry",
        bloc_interaction: extractRidFromBookingMiceUrl(node.href),
      },
    });
  }
}

new TPL_Property_Weeding(document.body);
