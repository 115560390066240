import {
  getDataLayer,
  handleGaTracking,
  extractCountryNameFromUrl,
} from "../../utils/js/tracking.ts";

class TPL_Brand_Destinations_Country {
  constructor(componentHost) {
    this.datalayer = getDataLayer();
    if (this.datalayer.pageName !== "mgallery::destinations::country") return;

    this.componentHost = componentHost;
    this.initEvents();
  }

  initEvents() {
    this.cellB551();
    // cellE551() -> done in countryCity.js (mutualized)
  }

  cellB551() {
    const nodes = this.componentHost.querySelectorAll(
      ".quick-access nav ul li a",
    );

    nodes?.forEach((node) => {
      const link = node.href;

      if (!link) return;

      handleGaTracking({
        node,
        eventName: "menu_inpage",
        additionalData: {
          bloc_name: "country localisation",
          menu_label: extractCountryNameFromUrl(link),
        },
      });
    });
  }
}

new TPL_Brand_Destinations_Country(document.body);
