import { getDataLayer, handleGaTracking } from "../../utils/js/tracking.ts";

class TPL_Brand_Magazine_HubTopicArticle {
  constructor(componentHost) {
    this.datalayer = getDataLayer();
    if (
      this.datalayer.pageName !== "mgallery::brand::magazine::hub" &&
      this.datalayer.pageName !== "mgallery::brand::magazine::topic" &&
      this.datalayer.pageName !== "mgallery::brand::magazine::topic::article"
    )
      return;

    this.componentHost = componentHost;
    // this.extractInfos();
    this.initEvents();
  }

  // extractInfos() {
  //   const infos = window.location.href.split("/magazine/")[1].split(".html")[0];
  //   const [topic, article] = infos.split("/");

  //   this.topic = topic;
  //   this.article = article;
  // }

  extractArticleNameFromUrl(url) {
    return url.split("/magazine/")[1].split("/")[1].split(".html")[0];
  }

  initEvents() {
    this.cellE805();
  }

  cellE805() {
    const nodes = this.componentHost.querySelectorAll(
      ".callout-container ul li",
    );

    nodes.forEach((node) => {
      const link = node.querySelector(".cmp-teaser__content a").href;

      handleGaTracking({
        node,
        eventName: "bloc_interact",
        additionalData: {
          bloc_name: "our recent articles",
          bloc_interaction: this.extractArticleNameFromUrl(link),
        },
      });
    });
  }
}

new TPL_Brand_Magazine_HubTopicArticle(document.body);
