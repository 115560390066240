/* eslint-disable no-unused-expressions */
import { QuickAccessV2 } from "@accor/ace-ui-core";

export default class MgalleryQuickAccessV2 extends QuickAccessV2 {
  static CLASS_NAMESPACE = "ace-quick-access-component-v2";

  constructor(componentHost) {
    super(componentHost);
    this.stickyBookingHeight = 0;
    this.lastScrollTarget = null;
    this.stickyAnchorNavigationHeight = null;
  }

  initialize() {
    super.initialize();
    this.observeStickyBooking();
    this.scrollWithSticky();

    window.addEventListener(CoreJS.DomEventConstants.SCROLL, () => {
      this.flagSticky = window.scrollY > this.componentHost.offsetTop;
      this.sticky();
    });
  }

  observeStickyBooking() {
    const stickyBookingContainer = document.querySelector(
      ".sticky-booking-engine",
    );
    if (!stickyBookingContainer) return;

    const observer = new MutationObserver(() => {
      const stickyBooking = document.querySelector(
        ".sticky-booking-engine.visible",
      );
      if (stickyBooking) {
        this.stickyBookingHeight = stickyBooking.offsetHeight;

        if (this.lastScrollTarget) {
          this.scrollToTarget(this.lastScrollTarget);
          this.lastScrollTarget = null;
        }
      }
    });

    observer.observe(stickyBookingContainer, { attributes: true });
  }

  scrollWithSticky() {
    this.componentHost.addEventListener("click", (event) => {
      const anchor = event.target.closest('a[data-cmp-hook-dropdown="filter"]');
      if (!anchor) return;

      event.preventDefault();
      const targetId = anchor.getAttribute("href").substring(1);
      if (targetId) {
        this.lastScrollTarget = targetId;
        this.scrollToTarget(targetId);
      }
    });
  }

  scrollToTarget(targetId) {
    const targetElement = document.getElementById(targetId);
    if (!targetElement) return;

    if (!this.stickyAnchorNavigationHeight) {
      const stickyNav = document.querySelector(
        ".ace-quick-access-component-v2",
      );
      this.stickyAnchorNavigationHeight = stickyNav
        ? stickyNav.offsetHeight
        : 0;
    }

    let top =
      targetElement.getBoundingClientRect().top +
      window.scrollY -
      this.stickyAnchorNavigationHeight;

    if (window.innerWidth >= CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg) {
      top -= this.stickyBookingHeight;
    }

    window.scrollTo({ top, behavior: "smooth" });
  }
}

CoreJS.BaseComponent.registerComponent(
  QuickAccessV2.CLASS_NAMESPACE,
  MgalleryQuickAccessV2,
  true,
);
