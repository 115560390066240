import {
  getDataLayer,
  handleGaTracking,
  extractRidFromBrandUrl,
  extractOfferNameFromUrl,
} from "../../utils/js/tracking.ts";

class TPL_Brand_Homepage {
  constructor(componentHost) {
    this.datalayer = getDataLayer();
    if (this.datalayer.pageName !== "mgallery::brand::homepage") return;

    this.componentHost = componentHost;
    this.initEvents();
  }

  initEvents() {
    this.cellK31();
    this.cellN31();
    this.cellQ31();
    this.cellT31();
    this.cellW31();
    this.cellZ31();
    this.cellAC31();
  }

  cellK31() {
    const nodes = this.componentHost.querySelectorAll(
      ".section-background-container .experience-fragment .cmp-slideshow__callouts a",
    );
    const vignetteIndex = (node) =>
      parseInt(
        node
          .closest(".section-background-container")
          .querySelector(".experience-fragment .cmp-slideshow__action-content")
          .innerText.split("/")[0]
          .trim(),
      );

    if (!nodes.length) return;

    nodes.forEach((node) => {
      handleGaTracking({
        node,
        eventName: "bloc_interact",
        additionalData: {
          bloc_name: "cardeditorial",
          bloc_interaction: "hotel page",
        },
        dynamic: {
          keyToUpdate: "bloc_type",
          functionToEval: vignetteIndex,
          staticValue: `${extractRidFromBrandUrl(node.href)} | `,
        },
      });
    });
  }

  cellN31() {
    const nodes = Array.from(
      this.componentHost.querySelectorAll(
        ".experience-fragment .callout-container .callout-section ul li a",
      ),
    ).filter((node) => !node.closest(".card-container"));

    if (!nodes.length) return;

    nodes.forEach((node) => {
      const link = node.href;

      if (!link) return;

      handleGaTracking({
        node,
        eventName: "bloc_interact",
        additionalData: {
          bloc_name: "hotels highlights",
          bloc_interaction: extractRidFromBrandUrl(link),
        },
      });
    });
  }

  cellQ31() {
    const node = this.componentHost.querySelector(
      ".acc-container .title + .button .ace-button a",
    );

    if (!node) return;

    handleGaTracking({
      node,
      eventName: "bloc_interact",
      additionalData: {
        bloc_name: "editorial highlight",
        bloc_interaction: "discover mgallery universe",
      },
    });
  }

  cellT31() {
    const node = this.componentHost.querySelector(
      ".experience-fragment .highlight .ace-button a",
    );

    if (!node) return;

    handleGaTracking({
      node,
      eventName: "bloc_interact",
      additionalData: {
        bloc_name: "offers",
        bloc_interaction: "see offer page",
        bloc_type: extractOfferNameFromUrl(node.href),
      },
    });
  }

  cellW31() {
    const node = this.componentHost.querySelector(
      ".title .ace-shorttitle__link a",
    );

    if (!node) return;

    handleGaTracking({
      node,
      eventName: "bloc_interact",
      additionalData: {
        bloc_name: "offers",
        bloc_interaction: "discover all offers",
      },
    });
  }

  cellZ31() {
    const nodes = this.componentHost.querySelectorAll(
      ".section-background-container .cmp-container .callout-section .ace-carousel-component-v2 li",
    );

    if (!nodes.length) return;

    nodes.forEach((node, index) => {
      const newsName = node
        .querySelector(".cmp-teaser__pretitle")
        .innerText.trim();

      handleGaTracking({
        node,
        eventName: "bloc_interact",
        additionalData: {
          bloc_name: "news",
          bloc_interaction: "see news page",
          bloc_type: `${newsName} | ${index + 1} `,
        },
      });
    });
  }

  cellAC31() {
    const nodes = this.componentHost.querySelectorAll(
      ".iconlist .ace-footer-icon li a",
    );

    if (!nodes.length) return;

    nodes.forEach((node) => {
      const socialMediaName = node
        .querySelector("img")
        ?.getAttribute("alt")
        ?.toLowerCase()
        .trim();

      handleGaTracking({
        node,
        eventName: "bloc_interact",
        additionalData: {
          bloc_name: "social",
          bloc_interaction: socialMediaName,
        },
      });
    });
  }
}

new TPL_Brand_Homepage(document.body);
