import {
  getDataLayer,
  handleGaTracking,
  extractRidFromBookingMiceUrl,
} from "../../utils/js/tracking.ts";

class TPL_Property_Meeting_Wedding {
  constructor(componentHost) {
    this.datalayer = getDataLayer();
    if (
      this.datalayer.pageName !== "mgallery::hotels::meetings" &&
      this.datalayer.pageName !== "mgallery::hotels::weddings"
    )
      return;

    this.componentHost = componentHost;
    this.initEvents();
  }

  initEvents() {
    this.cellB453();
    this.cellE453();
  }

  cellB453() {
    const node = this.componentHost.querySelector(
      ".booking-mice a.booking-mice__link",
    );

    if (!node) return;

    handleGaTracking({
      node,
      eventName: "bloc_interact",
      additionalData: {
        bloc_name: "make enquiry",
        bloc_interaction: extractRidFromBookingMiceUrl(node.href),
      },
    });
  }

  cellE453() {
    const node = this.componentHost.querySelector(
      ".welcome-message .ace-welcome-component__cta a",
    );

    if (!node) return;

    handleGaTracking({
      node,
      eventName: "bloc_interact",
      additionalData: {
        bloc_name: "send me a quote",
        bloc_interaction: extractRidFromBookingMiceUrl(node.href),
      },
    });
  }
}

new TPL_Property_Meeting_Wedding(document.body);
