import {
  getDataLayer,
  handleGaTracking,
  extractRidFromBrandUrl,
  extractWellnessTypeFromUrl,
} from "../../utils/js/tracking.ts";

class TPL_Property_Wellness_Hub {
  constructor(componentHost) {
    this.datalayer = getDataLayer();
    if (this.datalayer.pageName !== "mgallery::hotels::wellness::hub") return;

    this.componentHost = componentHost;
    this.initEvents();
  }

  initEvents() {
    this.cellE474();
    this.cellH474();
  }

  cellE474() {
    const nodes = this.componentHost.querySelectorAll(
      ".ace-card-editorial .cmp-slideshow__callout-item a",
    );

    if (!nodes.length) return;

    nodes.forEach((node, index) => {
      if (!node.href) return;

      handleGaTracking({
        node,
        eventName: "bloc_interact",
        additionalData: {
          bloc_name: extractWellnessTypeFromUrl(node.href),
          bloc_interaction: `${extractRidFromBrandUrl(node.href)}|${index + 1}`,
        },
      });
    });
  }

  cellH474() {
    const node = this.componentHost.querySelector(
      ".highlight .call-to-action a",
    );

    if (!node) return;

    handleGaTracking({
      node,
      eventName: "bloc_interact",
      additionalData: {
        bloc_name: "m moment",
        bloc_interaction: "discover this m moment",
      },
    });
  }
}

new TPL_Property_Wellness_Hub(document.body);
