// Stylesheets
import "./theme.scss";

// ACE Library JS
import "@accor/ace-ui-core";
import "@accor/ace-ui-core/src/components/common/header-menu-product/v1/js/header-menu-product";

// Import here all JS overlays that are overrigind ace-ui-core library. By default, the full
// original lib is imported

import "./components/structure/base-page-template/v1/js/base-page-template.js";

// COMMON JS Components
import "./components/common/navigation-header/v1/js/navigation-header.js";
import "./components/common/special-touches/v2/special-touches/js/special-touches.js";
import "./components/content/slideshow-dual/v1/js/slideshow-dual.js";
import "./components/common/callouts/v1/callout/js/callout.js";
import "./components/common/callouts/v1/callout-container/js/callout-container.js";
import "./components/common/core-booking-engine/v1/js/core-booking-engine.js";
import "./components/common/sticky-booking-engine/v1/js/sticky-booking-engine.js";
import "./components/common/booking-mice/v1/js/booking-mice.js";
import "./components/common/loyalty/v2/loyalty.js";
import "./components/common/quick-access/v2/js/quick-access.js";

// CONTENT JS Components
import "./components/content/slideshow-carousel/v1/js/slideshow-carousel.js";
import "./components/content/modal/v1/js/modal.js";
import "./components/content/gallery/v1/js/gallery.js";

// Tracking files
import "./components/tracking/tracking-brand-index.js";
import "./components/tracking/tracking-property-index.js";
import "./components/tracking/tracking-transverse.js";

// Overrides external bugged lib
import "./components/overrides/menumodo.js";

document.dispatchEvent(
  new Event(CoreJS.CustomDomEventConstants.THEME_LOADED_EVENT),
);
