export default class MGallerySlideshowDual extends CoreJS.BaseComponent {
  static CLASS_NAMESPACE = "slideshow-dual";

  constructor(componentHost, componentName) {
    super(componentHost, componentName);
  }

  initialize() {
    this.mainWrapper = this.componentHost.querySelector(
      ".slideshow-dual__main-wrapper",
    );

    if (!this.mainWrapper) {
      return;
    }

    this.slides = this.componentHost.querySelectorAll(
      ".slideshow-dual__slides-container .slideshow-dual__slide",
    );

    this.actions = this.componentHost.querySelectorAll(
      "[data-slideshow-dual-direction]",
    );

    this.arrows = Array.from(this.actions).filter(
      (action) => action.dataset.slideshowDualActionType === "arrow",
    );

    this.updateCurrentStateValue();
    this.initControlsEvents();
    this.initSwipeEvents();
  }

  initControlsEvents() {
    if (!this.actions) return;

    this.actions.forEach((action) =>
      action.addEventListener(CoreJS.DomEventConstants.CLICK, (event) =>
        this.stateChangeHandler(event),
      ),
    );
  }

  initSwipeEvents() {
    this.startX = 0;
    this.endX = 0;

    [
      CoreJS.DomEventConstants.TOUCH_START,
      CoreJS.DomEventConstants.TOUCH_END,
    ].forEach((triggerEventName) => {
      this.mainWrapper.addEventListener(triggerEventName, (event) => {
        switch (triggerEventName) {
          case CoreJS.DomEventConstants.TOUCH_START:
            this.startX = event.touches[0].clientX;
            break;

          case CoreJS.DomEventConstants.TOUCH_END:
            this.endX = event.changedTouches[0].clientX;
            this.handleSwipe(event);
            break;
        }
      });
    });
  }

  stateChangeHandler(event) {
    const direction = event.target.dataset.slideshowDualDirection;
    const isStraight =
      event.target.dataset.slideshowDualActionType === "straight"
        ? true
        : false;

    if (this.currentState === "middle") {
      this.mainWrapper.dataset.slideshowDualState = direction;
    } else {
      const oppositeDirection = this.currentState === "left" ? "right" : "left";

      if (direction === oppositeDirection) {
        this.mainWrapper.dataset.slideshowDualState = isStraight
          ? direction
          : "middle";
      }
    }

    this.updateCurrentStateValue();

    this.updateArrowsAvailability();
    this.a11yManager();
  }

  updateCurrentStateValue() {
    this.currentState = this.mainWrapper.dataset.slideshowDualState;
  }

  a11yManager() {
    this.updateSlidesTabIndexes();
    this.updateArrowsTabIndexesAndArias();
  }

  updateArrowsAvailability() {
    this.arrows.forEach((arrow) => {
      if (arrow.dataset.slideshowDualDirection === this.currentState) {
        arrow.dataset.arrowDisabled = "true";
      } else {
        if (arrow.dataset.arrowDisabled === "true") {
          arrow.dataset.arrowDisabled = "false";
        }
      }
    });
  }

  updateSlidesTabIndexes() {
    switch (this.currentState) {
      case "middle": {
        this.slides.forEach((slide) => {
          this.tabIndexDisabler(slide);
        });
        break;
      }

      case "left":
      case "right": {
        const oppositeDirection =
          this.currentState === "left" ? "right" : "left";

        const slideWithLinksToEnable = Array.from(this.slides).filter((slide) =>
          slide.classList.contains(
            `slideshow-dual__slide--${this.currentState}`,
          ),
        )[0];

        const slideWithLinksToDisable = Array.from(this.slides).filter(
          (slide) =>
            slide.classList.contains(
              `slideshow-dual__slide--${oppositeDirection}`,
            ),
        )[0];

        this.tabIndexEnabler(slideWithLinksToEnable);
        this.tabIndexDisabler(slideWithLinksToDisable);
        break;
      }
    }
  }

  tabIndexDisabler(slide) {
    slide.querySelectorAll("a").forEach((link) => {
      link.setAttribute("tabindex", "-1");
    });
  }

  tabIndexEnabler(slide) {
    slide.querySelectorAll("a").forEach((link) => {
      link.getAttribute("tabindex") && link.removeAttribute("tabindex");
    });
  }

  updateArrowsTabIndexesAndArias() {
    this.arrows.forEach((arrow) => {
      if (arrow.dataset.arrowDisabled === "true") {
        arrow.setAttribute("tabindex", "-1");
        arrow.setAttribute("aria-selected", "true");
      } else {
        arrow.getAttribute("tabindex") && arrow.removeAttribute("tabindex");
        arrow.setAttribute("aria-selected", "false");
        if (this.currentState !== "middle") {
          document.body.classList.contains("keyboard-navigation-is-active") &&
            arrow.focus();
        }
      }
    });
  }

  handleSwipe(event) {
    const alteredEvent = event;

    if (this.startX > this.endX) {
      alteredEvent.target.dataset.slideshowDualDirection = "right";
      this.stateChangeHandler(alteredEvent);
    } else if (this.startX < this.endX) {
      alteredEvent.target.dataset.slideshowDualDirection = "left";
      this.stateChangeHandler(alteredEvent);
    }
  }
}

CoreJS.BaseComponent.registerComponent(
  MGallerySlideshowDual.CLASS_NAMESPACE,
  MGallerySlideshowDual,
);
