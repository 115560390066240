import {
  getDataLayer,
  handleGaTracking,
  extractRidFromBrandUrl,
  extractOfferNameFromUrl,
} from "../../utils/js/tracking.ts";

class TPL_Brand_Offers_Hub {
  constructor(componentHost) {
    this.datalayer = getDataLayer();
    if (this.datalayer.pageName !== "mgallery::offers::homepage") return;

    this.componentHost = componentHost;
    this.initEvents();
  }

  initEvents() {
    this.cellE99();
    this.cellH99();
    this.cellK99();
  }

  cellE99() {
    const nodes = this.componentHost.querySelectorAll(
      ".callout-container .callout-section ul li",
    );

    nodes.forEach((node, index) => {
      const link = node.querySelector("a").href;

      handleGaTracking({
        node,
        eventName: "bloc_interact",
        additionalData: {
          bloc_name: "offers",
          bloc_interaction: "see offer page",
          bloc_type: `${extractOfferNameFromUrl(link)} | ${index + 1}`,
        },
      });
    });
  }

  cellH99() {
    const node = this.componentHost.querySelector(".highlight .ace-button a");

    handleGaTracking({
      node,
      eventName: "bloc_interact",
      additionalData: {
        bloc_name: "highlighted offer",
        bloc_interaction: "see offer details",
        bloc_type: extractOfferNameFromUrl(node?.href),
      },
    });
  }

  cellK99() {
    const node = this.componentHost.querySelector(
      ".section-background-container .highlight .call-to-action a",
    );

    handleGaTracking({
      node,
      eventName: "bloc_interact",
      additionalData: {
        bloc_name: "m moments",
        bloc_interaction: "discover",
      },
    });
  }
}

new TPL_Brand_Offers_Hub(document.body);
