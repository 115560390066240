import { HeaderNavigation } from "@accor/ace-ui-core";

export default class MGalleryHeaderNavigation extends HeaderNavigation {
  static CLASS_NAMESPACE = "ace-header-navigation";

  /** @inheritDoc */
  // Constructor function that takes the componentHost parameter
  constructor(componentHost, componentName) {
    // Call the constructor of the parent class (BaseComponent)
    super(componentHost, componentName);
  }

  /** @inheritDoc */
  // Initialize Function
  initialize() {
    super.initialize();

    this.HeadElement =
      document.querySelector("main > div > div").firstElementChild;

    if (
      this.HeadElement.classList.contains("immersive-heading") ||
      this.HeadElement.classList.contains("heading-hero")
    ) {
      this.HeadElement.style.marginTop = "0";
    } else {
      this.headerComp = this.componentHost.closest("header");
      this.headerHeight = this.headerComp.offsetHeight;
      this.marginTop;
      if (window.innerWidth >= CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg) {
        this.marginTop = 96;
      } else if (
        window.innerWidth >= CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.md
      ) {
        this.marginTop = 80;
      } else if (
        window.innerWidth >= CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.sm
      ) {
        this.marginTop = 64;
      }
      this.HeadElement.style.marginTop = `${this.marginTop}px`;
      this.HeadElement.style.marginTop = `${this.headerHeight + this.marginTop}px`;
    }
    this.aceImage = this.HeadElement.querySelector(".ace-image");
    this.headerComp = this.componentHost.closest("header");
    this.myDiv = this.componentHost.querySelector(
      ".ace-header-navigation__main-container.--jsfied",
    );
    this.componentHost
      .querySelector("#ace-burger-menu")
      ?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
        if (this.myDiv.classList.contains("show")) {
          document
            .querySelector(".ace-quick-access-component-v2 .cmp-filter")
            ?.classList?.add("mob-sticky");
        } else {
          document
            .querySelector(".ace-quick-access-component-v2 .cmp-filter")
            ?.classList?.remove("mob-sticky");
        }
        if (
          window.innerWidth < CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg &&
          window.innerWidth > CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.md
        ) {
          document.querySelector("#panel-0").classList.add("showPanel");
        }
      });

    this.posContainer = this.componentHost.querySelector(
      ".ace-header-navigation__pos-container",
    );

    this.headerBlur();
    this.subMenuBtn();

    this.geoAndCurrencyBtns = this.menuMainContainer?.querySelectorAll('.ace-geolocation-selector__button, .ace-currency-selector__button');
    this.geoAndCurrencyBtns?.forEach((button) => {
      button.addEventListener(CoreJS.DomEventConstants.CLICK, (e) => {
        this.posContainer?.classList?.add("appear-on-top")
      })
    });
  }

  headerBlur() {
    if (
      this.aceImage?.getAttribute("data-loaded") == "true" ||
      this.HeadElement.querySelector(".content-wrapper")
    ) {
      this.headerComp?.classList?.add("HeaderInverse");
      window.addEventListener(CoreJS.DomEventConstants.SCROLL, () => {
        if (this.HeadElement.getBoundingClientRect().bottom <= 0) {
          this.headerComp?.classList?.remove("HeaderInverse");
        } else {
          this.headerComp?.classList?.add("HeaderInverse");
        }
      });
    }
  }

  subMenuBtn() {
    this.btnSubMenu?.forEach((button) => {
      button.addEventListener(CoreJS.DomEventConstants.CLICK, (e) => {
        this.posContainer?.classList?.remove("appear-on-top");
        this.ariaControls = e.currentTarget?.getAttribute("aria-controls");
        const isExpanded =
          e.currentTarget.classList.contains("activeBtn") &&
          this.menuMainContainer
            ?.querySelector(`#${this.ariaControls}`)
            ?.classList.contains("showPanel");
        if (!isExpanded) {
          const dataTracking = e.currentTarget.dataset.tracking;
          const position = e.currentTarget.parentElement.dataset.menuPosition;
          this.subMenuPosition = position;
          let moreBtnHeader;
          const datasEvent = {
            event: "GAevent",
            eventAction: "Menu - Click on CTA",
            eventCategory: "Navigation",
            eventLabel: `${dataTracking ? dataTracking : ""}`,
          };
          if (
            e.currentTarget?.classList?.contains("link-navigation__more-btn")
          ) {
            moreBtnHeader = "more";
          } else {
            moreBtnHeader = `${dataTracking ? dataTracking.toLowerCase() : ""}`;
          }
          const dataGA4Event = {
            event: "GA4event",
            eventName: "menu_interact",
            event_data: {
              pagename: `${this.dataLayerjson.pageName || ""}`,
              menu_position: `${position || ""}`,
              menu_label: moreBtnHeader,
            },
          };
          if (!this.isMercure) {
            TagManager.trackEvent(datasEvent);
            window.dataLayer.push({
              event_data: null,
            });
            TagManager.trackEvent(dataGA4Event);
          }
        }
      });
    });

    this.loginConnectBtn?.addEventListener(
      CoreJS.DomEventConstants.CLICK,
      () => {
        this.closeSubMenuDropdownPanelHanlder();
        this.loginConnectPanelCloseBtn = this.componentHost.querySelector(
          `.${HeaderNavigation.CLASS_NAMESPACE}__loginconnect .login-nav__item--close__button`,
        );
        this.loginConnectPanelCloseBtn?.blur();
      },
    );
  }
}
// Register the NavBar component with the BaseComponent
CoreJS.BaseComponent.registerComponent(
  HeaderNavigation.CLASS_NAMESPACE,
  MGalleryHeaderNavigation,
  true,
);
