import {
  getDataLayer,
  handleGaTracking,
  extractRidFromBrandUrl,
} from "../../utils/js/tracking.ts";

class TPL_Brand_Destinations_HubCityCountryInterest {
  constructor(componentHost) {
    this.datalayer = getDataLayer();
    if (
      this.datalayer.pageName !== "mgallery::destinations::hub" &&
      this.datalayer.pageName !== "mgallery::destinations::country" &&
      this.datalayer.pageName !== "mgallery::destinations::city" &&
      this.datalayer.pageName !== "mgallery::destinations::interest"
    )
      return;

    this.componentHost = componentHost;
    this.initEvents();
  }

  initEvents() {
    this.cellB519();
    this.cellH519();
    this.cellN519();
    this.cellK519();
    this.cellQ519();
  }

  cellB519() {
    const node = this.componentHost.querySelector(
      ".core-booking-engine button[type='submit']",
    );

    handleGaTracking({
      node,
      eventName: "booking_form_submit",
    });
  }

  cellH519() {
    const node = this.componentHost.querySelector(
      ".callout-container .ace-loyalty__inner button",
    );

    handleGaTracking({
      node,
      eventName: "bloc_interact",
      additionalData: {
        bloc_name: "hotels push",
        bloc_interaction: "see more hotels",
      },
    });
  }

  cellN519() {
    const nodes = this.componentHost.querySelectorAll(
      "ul.ace-carousel__content li",
    );

    nodes.forEach((node) => {
      const linkName = node
        .querySelector(".cmp-teaser__pretitle a")
        ?.innerText?.toLowerCase()
        .trim();

      if (!linkName) return;

      handleGaTracking({
        node,
        eventName: "bloc_interact",
        additionalData: {
          bloc_name: "explore",
          bloc_interaction: linkName,
        },
      });
    });
  }

  cellK519() {
    const node = this.componentHost.querySelector(
      ".callout-container-api .ace-showmap__button",
    );

    handleGaTracking({
      node,
      eventName: "map_open",
      additionalData: {
        bloc_name: "map",
      },
    });
  }

  cellQ519() {
    const nodes = this.componentHost.querySelectorAll(
      ".callout-container-api .callout-section ul li",
    );

    nodes.forEach((node, index) => {
      const link = node.querySelector(".cmp-teaser__pretitle a")?.href;

      if (!link) return;

      handleGaTracking({
        node,
        eventName: "bloc_interact",
        additionalData: {
          bloc_name: "hotels push",
          bloc_interaction: `${extractRidFromBrandUrl(link)} | ${index + 1}`,
        },
      });
    });
  }
}

new TPL_Brand_Destinations_HubCityCountryInterest(document.body);
