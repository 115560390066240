/**
 * Class representing a Ace SlideShowCarousel Component instance
 * @author Naman
 * @class SlideShowCarousel
 * @classdesc Ace MGallerySlideShowCarousel to inherit the SlideShowCarousel core component
 * @extends SlideShowCarousel
 * @version 1.0
 */
import { SlideShowCarousel } from "@accor/ace-ui-core";

// Assume CoreJS is available and ResponsiveConstants are defined

export default class MGallerySlideShowCarousel extends SlideShowCarousel {
  constructor(componentHost, componentName) {
    super(componentHost, componentName);
  }

  initialize() {
    super.initialize();
    this._colContainerVal = this.componentHost.querySelector(
      ".cmp-carouselslideshow__content",
    );

    if (
      window.screen.width < CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg &&
      this._colContainerVal
    ) {
      this.disablePaginationLoop();
    }
  }

  disablePaginationLoop() {
    this._active = 1;
    const controls = this.componentHost.querySelector(
      ".cmp-carouselslideshow__actions",
    );
    const previousButton = controls.querySelector(
      ".cmp-carouselslideshow__action--previous",
    );
    const nextButton = controls.querySelector(
      ".cmp-carouselslideshow__action--next",
    );

    previousButton.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
      this._colContainerVal.scrollLeft -= this._colContainerVal.querySelector(
        ".cmp-carouselslideshow__item",
      )?.offsetWidth;
    });
    nextButton.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
      this._colContainerVal.scrollLeft += this._colContainerVal.querySelector(
        ".cmp-carouselslideshow__item",
      )?.offsetWidth;
    });
  }
}

// Register the SlideShow component with the BaseComponent
CoreJS.BaseComponent.registerComponent(
  SlideShowCarousel.CLASS_NAMESPACE,
  MGallerySlideShowCarousel,
  true,
);
